.App {
  text-align: center;
  background-color: #000;
  color: white;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  border-radius: 5px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.box {
  margin-top: 25vmin;
}

@media screen and (min-width:700px) and (max-width:1055px) {
  .box {
    margin-top: 40vmin;
  }
  .card {
    margin-top: 7px;
  }
}

@media screen and (max-width:700px) {
  .box {
    margin-top: 54vmin;
  }
  .card {
    margin-top: 7px;
  }
  .modal-card{
    width: 300px;
  }
  .team-pic{
    height: 225px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  align-items: center; 
  justify-content: space-around;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  text-align: center;
}

.team-pic {
  max-height: 450px;
}

.contact-box {
  background-color: white;
  margin-top: 7px;
  border-radius: 5px;
}

.contact-button {
  margin-top: 7px;
}

.center {
  display: flex;
  justify-content: center;
}

.apart {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
}

.outline {
  margin: 15px;
  padding: 60px;
  outline: 6px solid #1894C1;
  background-color: #2EC4FA;
  border-radius: 10px;
}

.outline-2 {
  margin: 15px;
  padding: 60px;
  outline: 6px solid #07BC62;
  background-color: #0EFF87;
  border-radius: 10px;
}